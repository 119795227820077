<template>
  <div>
    <c-search-box @enter="getList">
      <template slot="search">
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <c-plant type="search" name="plantCd" v-model="searchParam.plantCd" />
        </div>
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <c-dept type="search" label="부서" name="deptCd" v-model="searchParam.deptCd" />
        </div>
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <c-datepicker
            :range="true"
            label="검진기간"
            name="period"
            defaultStart="-6M"
            defaultEnd="6M"
            v-model="period"
          />
        </div>
      </template>
    </c-search-box>
    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
      <c-table
        ref="table"
        title="대상자 목록"
        tableId="table"
        :columns="grid.columns"
        :data="grid.data"
        :merge="grid.merge"
        :checkClickFlag="false"
        :columnSetting="false"
        selection="multiple"
        :isExcelDown="false"
        rowKey="heaCheckupResultId"
        :hideBottom="true"
        gridHeight="600px"
      >
        <template slot="table-button">
          <q-btn-group outline >
            <c-btn
              v-if="editable"
              :url="saveUrl"
              :isSubmit="isSave"
              :param="selectData"
              :mappingType="mappingType"
              label="유소견자 지정"
              icon="save"
              @beforeAction="saveRow"
              @btnCallback="saveCallback" />
              <c-btn label="검색" icon="search" @btnClicked="getList" />
            <!-- <c-btn label="제외" v-if="editable && grid.data.length > 0" icon="remove" @btnClicked="removeRow" /> -->
          </q-btn-group>
        </template>
      </c-table>
    </div>
    <c-dialog :param="popupOptions"></c-dialog>
  </div>
</template>

<script>
import { uid } from 'quasar';
import selectConfig from '@/js/selectConfig';
import transactionConfig from '@/js/transactionConfig';
export default {
  name: 'dept-harmful-factors-detail',
  props: {
    popupParam: {
      type: Object,
      default: () => ({
        deptCd: '',
      }),
    },
  },
  data() {
    return {
      mappingType: 'POST',
      saveUrl: '',
      deleteUrl: '',
      revlistUrl: '',
      chemName: '',
      checkUrl: '',
      subListUrl: '',
      subSaveUrl: '',
      subDeleteUrl: '',
      editable: true,
      isSave: false,
      isdelete: false,
      isRevision: false,
      updateMode: false,
      searchParam: {
        plantCd: null,
        deptCd: null,
        startYmd: '',
        endYmd: '',
      },
      data: {
        hazardCd: '',
        hazardClassFstCd: null,
        hazardClassSecCd: null,
        hazardName: '',
        mdmChemMaterialId: '',
        useFlag: 'Y',
        mttrid: '',
        casNo: '',
        workEnvFlag: 'Y',
        speHealthFlag: 'Y',
        workMeasCycleCd: null,
        factorMeasCycleCd: null,
        regUserId: '',
        chgUserId: '',

        deptList: [],
      },
      period: [],
      popupOptions: {
        target: null,
        title: '',
        visible: false,
        width: '90%',
        top: '10px',
        param: {},
        closeCallback: null,
      },
      useFlagItems: [],
      workFlagItems: [],
      occSecItems: [],
      grid: {
        merge: [
          { index: 0, colName: 'mergeGroup' },
          { index: 1, colName: 'mergeGroup' },
          { index: 2, colName: 'mergeGroup' },
          { index: 3, colName: 'mergeGroup' },
          // { index: 3, colName: 'mergeGroup' },
        ],
        columns: [
          {
            name: 'plantName',
            field: 'plantName',
            label: '사업장',
            align: 'center',
            style: 'width:60px',
            sortable: false,
          },
          {
            name: 'deptName',
            field: 'deptName',
            label: '부서',
            align: 'center',
            style: 'width:60px',
            sortable: false,
          },
          {
            name: 'userName',
            field: 'userName',
            label: '대상자',
            align: 'center',
            style: 'width:100px',
            sortable: false,
          },
          {
            name: 'checkupDate',
            field: 'checkupDate',
            label: '검진일',
            align: 'center',
            style: 'width: 100px',
            sortable: false,
          },
          {
            name: 'judgmentName',
            field: 'judgmentName',
            label: '판정',
            align: 'center',
            style: 'width: 50px',
            sortable: false,
          },
          {
            name: 'diseaseName',
            field: 'diseaseName',
            label: '질환',
            align: 'center',
            style: 'width: 120px',
            sortable: false,
          },
        ],
        data: [],
      },
      selectData: [],
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      this.workFlagItems = [
        { code: 'Y', codeName: this.$language('관련') },
        { code: 'N', codeName: this.$language('미관련') },
      ]
      this.useFlagItems = [
        { code: 'Y', codeName: this.$language('사용') },
        { code: 'N', codeName: this.$language('미사용') },
      ]
      this.editable = this.$route.meta.editable;
      // url setting
      this.detailUrl = selectConfig.hea.checkup.suspect.user.target.url;
      this.saveUrl = transactionConfig.hea.checkup.suspect.user.save.url;

      this.getList();
    },
    getList() {
      if (this.period) {
        this.searchParam.startYmd = this.period[0];
        this.searchParam.endYmd = this.period[1];
      } else {
        this.searchParam.startYmd = '';
        this.searchParam.endYmd = '';
      }
      this.$http.url = this.detailUrl;
      this.$http.type = 'GET';
      this.$http.param = this.searchParam;
      this.$http.request((_result) => {
        this.grid.data = this.$_.clone(_result.data);
      },);
    },
    saveRow() {
      let selectData = this.$refs['table'].selected;
      if (!selectData || selectData.length === 0) {
        window.getApp.$emit('ALERT', {
          title: '안내',
          message: '선택된 항목이 없습니다.',
          type: 'warning', // success / info / warning / error
        });
      } else {
        window.getApp.$emit('CONFIRM', {
          title: '확인',
          message: '유소견자 지정하시겠습니까?',
          
          type: 'info', // success / info / warning / error
          // 확인 callback 함수
          confirmCallback: () => {
            this.selectData = selectData;

            this.$_.forEach(selectData, _item => {
              this.selectData.push({
                suspectId: uid(),
                heaCheckupResultId: _item.heaCheckupResultId,
                userId: _item.userId,
                userName: _item.userName,
                regUserId:  this.$store.getters.user.userId,
                chgUserId:  this.$store.getters.user.userId,
              })
            })

            this.isSave = !this.isSave;
            this.$refs['table'].selected = []
          },
          // 취소 callback 함수
          cancelCallback: () => {
          },
        });
      }
    },
    saveCallback() {
      window.getApp.$emit('APP_REQUEST_SUCCESS');
      this.$emit('closePopup')
    },
    removeRow() {
      let selectData = this.$refs['table'].selected;
      if (!selectData || selectData.length === 0) {
        window.getApp.$emit('ALERT', {
          title: '안내',
          message: '선택된 항목이 없습니다.',
          type: 'warning', // success / info / warning / error
        });
      } else {
        this.$_.forEach(selectData, _item => {
          this.grid.data = this.$_.reject(this.grid.data, _item);
        })
        this.$refs['table'].selected = []
      }
    },
  }
};
</script>
